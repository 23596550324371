<template>
  <table-view
    ref="tableView"
    url="/dispatch/quantityNumber/listForPage"
    :filterable="false"
    :filter-form="dataForm"
    :uploadable="true"
    :exportable="isAuthed('quantity_self_export')"
    export-url="/dispatch/quantityNumber/download"
    show-index
    single
  >
    <el-form slot="search" @keyup.enter.native="query" inline>
      <el-form-item label="生产日期">
        <el-date-picker type="date" v-model="dataForm.startDate" value-format="yyyy-MM-dd"></el-date-picker>
      </el-form-item>
      <el-form-item label="到">
        <el-date-picker type="date" v-model="dataForm.endDate" value-format="yyyy-MM-dd"></el-date-picker>
      </el-form-item>
      <el-select filterable clearable v-model="dataForm.baseId" placeholder="基地">
        <el-option
          v-for="s in baseList"
          :key="s.id"
          :label="s.name"
          :value="s.id"
        ></el-option>
      </el-select>
      <el-button @click="query" type="primary">查询</el-button>
    </el-form>
    <template slot="append">
      <template v-for="h in headers">
        <el-table-column v-if="h.children" :label="h.label" :key="h.id" header-align="center">
          <tabel-column-template v-for="s in h.children" :key="s.id" :header="s"></tabel-column-template>
        </el-table-column>
        <tabel-column-template v-else :key="h.id" :header="h"></tabel-column-template>
      </template>
    </template>
  </table-view>
</template>

<script>
import TableView from '@/components/templates/table-view'
import TabelColumnTemplate from '@/components/templates/table-column-template'

export default {
  name: 'report-car-amount',

  components: { TableView, TabelColumnTemplate },

  data () {
    return {
      headers: [
        { id: 1, prop: 'mixingTable', label: '基地', width: 100 },
        { id: 4,
          label: '1月自有泵',
          children: [
            { id: 41, prop: 'insider', label: '泵别', width: 100 },
            { id: 42, prop: 'constructionUnit', label: '自有方量', width: 100 },
            { id: 43, prop: 'siteName', label: '出车次数', minWidth: 100 },
            { id: 44, prop: 'step', label: '平均单次', width: 100 },
            { id: 45, prop: 'step', label: '租赁方量', width: 100 },
            { id: 46, prop: 'step', label: '出车次数', width: 100 },
            { id: 47, prop: 'step', label: '平均单次', width: 100 },
            { id: 48, prop: 'step', label: '总方量', width: 100 },
            { id: 49, prop: 'step', label: '总出车次数', width: 100 },
            { id: 40, prop: 'step', label: '平均单次', width: 100 }
          ]
        },
        { id: 2, prop: 'mixingTable', label: '1月自有占比', width: 100 },
        { id: 5,
          label: '1月外借泵',
          children: [
            { id: 51, prop: 'insider', label: '方量', width: 100 },
            { id: 52, prop: 'constructionUnit', label: '出车次数', width: 100 },
            { id: 53, prop: 'siteName', label: '平均单次', minWidth: 100 }
          ]
        },
        { id: 6, prop: 'mixingTable', label: '月自运率', width: 100 }
      ],
      dataForm: {
        startDate: '',
        endDate: '',
        baseId: ''
      },
      baseList: []
    }
  },

  methods: {
    query () {
      this.$refs.tableView.queryData()
    }
  },

  async created () {
    const data = await this.$http({
      url: this.$http.adornUrl('/user/base/list'),
      method: 'post'
    })
    this.baseList = data.datas
  }
}
</script>
